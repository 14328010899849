const data = {
  testimonials: [
    {
      id: 1,
      description:
        'BlindID is great and I use it all the time after major update connection time are quick then before.If you want speak Anonymous this app for you ',
      name: 'Rebbecca B.',
      designation: 'Collage Student',
    },
    {
      id: 2,
      description:
        'Great app for having fun in your spare time.',
      name: 'Paresh K.',
      designation: 'Marketing Manager',
    },

    {
      id: 3,
      description:
        '"I really enjoy trying to guess or imagine the appearance of the people from their voice."',
      name: 'Keira M.',
      designation: 'Traveler',
    },

    {
      id: 4,
      description:
        'Whenever I feel bored, I make calls on BlindID. You can always find someone to talk there.',
      name: 'Amber S.',
      designation: 'Student',
    },

    {
      id: 5,
      description:
        'This app is like my psychiatrist. I use it to tell my problems which I can not tell my family and friends. It makes me relaxed. And, it is great because nobody knows my identity.',
      name: 'Anonymous',
    },
  ],
};
export default data;
