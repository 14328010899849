const data = {
  features: [
    {
      id: 1,
      icon: 'flaticon-annonymous',
      title: 'Anonymity',
      description:
        'There is no need for any information like user name, phone number, profile picture or gender. You just need to be creative and have good communication skills to enjoy using BlindID.',
    },
    {
      id: 2,
      icon: 'flaticon-user',
      title: 'Random match',
      description:
        'You can match with a random user each time. Guess who is next?',
    },
    {
      id: 3,
      icon: 'flaticon-stopwatch',
      title: 'Limited time',
      description: 'You have limited time for each call, use your time wisely!',
    },
    {
      id: 4,
      icon: 'flaticon-conversation',
      title: 'Friend Call',
      description: 'If you enjoy your conversation, tap on the like button. By that way, you can always call your new friend.',
    },
  ],
};
export default data;
