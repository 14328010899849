import Icon1 from '../../../assets/image/app/6.svg';
import Icon2 from '../../../assets/image/app/1.svg';
import Icon3 from '../../../assets/image/app/2.svg';
import Icon4 from '../../../assets/image/app/3.svg';
import Icon5 from '../../../assets/image/app/4.svg';
import Icon6 from '../../../assets/image/app/5.svg';

const data = {
  features: [
    {
      id: 1,
      image: Icon2,
      title: 'Choose one of the topics',
    },
    {
      id: 2,
      image: Icon1,
      title: 'Tap on the call button to connect someone',
    },
    {
      id: 3,
      image: Icon3,
      title: 'Like or dislike after the call',
    },

    {
      id: 5,
      image: Icon5,
      title: 'Call your new friends whenever you want',
    },

    {
      id: 4,
      image: Icon4,
      title: 'Make longer calls and have more friends with higher levels',
    },
  ],
};
export default data;
