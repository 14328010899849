const data = {
  menuWidget: [
    {
      id: 2,
      menuItems: [
        {
          id: 2,
          url: '/terms',
          text: 'Terms & Conditions',
        },
        {
          id: 3,
          url: '/policy',
          text: 'Privacy Policy',
        }
      ],
    },
  ],
};
export default data;
