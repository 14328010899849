const data = {
  menuItems: [
    {
      label: 'Features',
      path: '#services',
      offset: '100',
    },
    {
      label: ' How to use BlindID? ',
      path: '#keyfeature',
      offset: '0',
    },
    {
      label: 'User Reviews',
      path: '#testimonialSection',
      offset: '100',
    },
  ],
};
export default data;
