import { socialTwitter } from 'react-icons-kit/ionicons/socialTwitter';
import { socialFacebook } from 'react-icons-kit/ionicons/socialFacebook';
import { socialInstagram } from 'react-icons-kit/ionicons/socialInstagram';

export const SOCIAL_PROFILES = [
  {
    icon: socialTwitter,
    url: 'https://twitter.com/BlindIDApp',
  },
  {
    icon: socialFacebook,
    url: 'https://www.facebook.com/blindidapp',
  },
  {
    icon: socialInstagram,
    url: 'https://www.instagram.com/blindidapp/',
  }
];