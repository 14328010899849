import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import Image from 'reusecore/src/elements/Image';
import FeatureBlock from '../../../components/FeatureBlock';
import ParticlesComponent from '../particles';
import Container from '../../../components/UI/Container';
import { email } from 'react-icons-kit/ionicons/email';
import { ic_arrow_forward } from 'react-icons-kit/md/ic_arrow_forward';
import { BannerCircleShape, BannerSquareShape } from '../app.style';
import { ButtonWrapper } from './banner.style';
import AppScreenshot from '../../../assets/image/app/mobile.png';
import googleplay from '../../../assets/image/app/googleplay.jpg';
import applestore from '../../../assets/image/app/applestore.jpg';
import huaweistore from '../../../assets/image/app/huaweiappstore.jpg';

const DomainSection = ({
  SectionWrapper,
  row,
  col,
  title,
  description,
  button,
  image,
  imageArea,
  btnStyle,
  btnStyleTwo,
  textArea,
  discountAmount,
  discountText,
}) => {
  return (
    <Box {...SectionWrapper}>
      <ParticlesComponent />
      <BannerSquareShape />
      <BannerCircleShape />
      <Container>
        <Box {...row}>
          <Box {...col}>
            <FeatureBlock
              title={<Heading {...title} />}
              description={<Text {...description} />}
            />
            <Box {...row}>
              <Box {...col}>
                <ButtonWrapper>
                  <Link href="https://play.google.com/store/apps/details?id=com.dorianlabs.blindid&hl=en">
                    <a>
                      <Image
                        src={googleplay}
                        alt="googleplay"
                        {...button}
                        {...btnStyle}
                      />
                    </a>
                  </Link>
                </ButtonWrapper>
              </Box>
              <Box {...col}>
                <ButtonWrapper>
                  <Link href="https://itunes.apple.com/us/app/blindid-meet-new-people-via/id1038889456?mt=8">
                    <a>
                      <Image
                        src={applestore}
                        alt="applestore"
                        {...button}
                        {...btnStyleTwo}
                      />
                    </a>
                  </Link>
                </ButtonWrapper>
              </Box>
              <Box {...col}>
                <ButtonWrapper>
                  <Link href="https://appgallery.huawei.com/app/C100861363">
                    <a>
                      <Image
                        src={huaweistore}
                        alt="huaweistore"
                        {...button}
                        {...btnStyleTwo}
                      />
                    </a>
                  </Link>
                </ButtonWrapper>
              </Box>
            </Box>
          </Box>
          <Box {...col} {...imageArea}>
            <Image src={AppScreenshot} alt="Domain Image" {...image} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

DomainSection.propTypes = {
  SectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  btnStyle: PropTypes.object,
  btnStyleTwo: PropTypes.object,
  discountAmount: PropTypes.object,
  discountText: PropTypes.object,
  textArea: PropTypes.object,
};

DomainSection.defaultProps = {
  SectionWrapper: {
    as: 'section',
    pt: '80px',
    pb: '80px',
    overflow: 'hidden',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '50%', '44%', '44%'],
    mt: '-80px',
  },
  // textArea: {
  // 	width: [1, '42%'],
  // },
  imageArea: {
    width: ['0%', '0%', '43%', '35%', '50%'],
    ml: 'auto',
  },
  title: {
    content: 'Meet new people whenever you want.',
    fontSize: ['26px', '30px', '30px', '48px', '60px'],
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '-0.01px',
    mb: '20px',
  },
  description: {
    content: 'Make 100% anonymous calls, add friends and have fun.',
    fontSize: '20px',
    color: '#343d48',
    lineHeight: '33px',
    mb: '10px',
  },
  button: {
    title: 'Download Play Store',
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    color: '#fff',
    borderRadius: '4px',
    mt: '100px',
    colors: 'primaryWithBg',
  },
  image: {
    ml: 'auto',
    mt: '30px',
  },
  btnStyle: {
    minWidth: '250px',
    fontSize: '14px',
    fontWeight: '500',
    pl: '48px',
    pr: '10px',
  },
  btnStyleTwo: {
    title: 'Download Apple Store',
    type: 'button',
    minWidth: '238px',
    fontSize: '14px',
    fontWeight: '500',
    bg: '#fff',
    ml: '25px',
    pl: '22px',
    pr: '22px',
    color: 'rgb(26, 115, 232)',
  },
  textArea: {
    width: ['100%', '100%', '50%', '55%', '55%'],
  },
  discountAmount: {
    content: 'update',
    fontSize: '14px',
    fontWeight: '600',
    color: '#fff',
    mb: 0,
    as: 'span',
    mr: '0.4em',
    bg: 'rgb(26, 115, 232)',
  },
  discountText: {
    content: 'Version 2.5.0 has just released .',
    fontSize: '13px',
    fontWeight: '400',
    color: '#0f2137',
    mb: 0,
    as: 'span',
    ml: '10px',
  },
};

export default DomainSection;
